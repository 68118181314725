<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add User</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="6">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Role (*)</span>
            <v-select
              name="role"
              label="label"
              v-validate="'required'"
              data-vv-as="Role"
              :options="roleOptions"
              :reduce="(role) => role.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.role"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("role")
            }}</span>
          </vs-col>
          <vs-col
            vs-w="12"
            class="mb-3"
            v-if="payload.role === 'tenant'"
          >
            <span class="mb-2 block text-sm">Property (*)</span>
            <v-select
              name="hotelId"
              label="name"
              v-validate="'required'"
              data-vv-as="Property"
              :options="brandOptions"
              :reduce="(brand) => brand.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.hotelId"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("hotelId")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              name="name"
              v-validate="'required'"
              data-vv-as="Name"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              label="Full Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              name="email"
              v-validate="'required'"
              data-vv-as="email"
              type="email"
              class="w-full"
              icon-pack="feather"
              icon="icon-mail"
              icon-no-border
              label="E-mail Address (*)"
              v-model="payload.email"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("email")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              name="phoneNumber"
              v-validate="'required'"
              data-vv-as="Phone Number"
              class="w-full"
              icon-pack="feather"
              icon="icon-smartphone"
              icon-no-border
              label="Mobile Phone Number (*)"
              v-model="payload.phoneNumber"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("phoneNumber")
            }}</span>
          </vs-col>

<!--          <vs-col vs-w="12" class="mb-3">-->
<!--            <vs-input-->
<!--              type="password"-->
<!--              class="w-full"-->
<!--              icon-pack="feather"-->
<!--              icon="icon-lock"-->
<!--              icon-no-border-->
<!--              label="Password (*)"-->
<!--              v-model="payload.password"-->
<!--            />-->
<!--            <span class="block text-sm mt-2 text-danger">{{-->
<!--              errors.first("password")-->
<!--            }}</span>-->
<!--          </vs-col>-->

<!--          <vs-col vs-w="12" class="mb-6">-->
<!--            <vs-input-->
<!--              type="password"-->
<!--              class="w-full"-->
<!--              icon-pack="feather"-->
<!--              icon="icon-lock"-->
<!--              icon-no-border-->
<!--              label="Password Confirmation (*)"-->
<!--              v-model="payload.password_confirmation"-->
<!--            />-->
<!--          </vs-col>-->

          <vs-col vs-w="12" class="mb-2">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button :loading="isSaving" class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>

<!--      <vs-col vs-w="6" class="pl-8">-->
<!--        <div class="flex items-start flex-col sm:flex-row">-->
<!--          <img :src="url" class="rounded mr-4" width="350" height="350" />-->
<!--          <div>-->
<!--            <input-->
<!--              type="file"-->
<!--              @change="addImage"-->
<!--              class="hidden"-->
<!--              ref="update_logo_input"-->
<!--              accept="image/*"-->
<!--            />-->
<!--            <vs-button-->
<!--              @click="$refs.update_logo_input.click()"-->
<!--              class="mr-4 mb-2"-->
<!--              type="filled"-->
<!--              icon-pack="feather"-->
<!--              icon="icon-edit"-->
<!--            ></vs-button>-->
<!--            <vs-button-->
<!--              @click="removeImage"-->
<!--              type="border"-->
<!--              color="danger"-->
<!--              icon-pack="feather"-->
<!--              icon="icon-trash"-->
<!--            ></vs-button>-->
<!--          </div>-->
<!--        </div>-->
<!--        <span class="block text-sm mt-2 text-danger">{{-->
<!--          errors.first("avatar")-->
<!--        }}</span>-->
<!--      </vs-col>-->
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

// Store Module
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js";
import identificationTypeStore from "@/store/identification-type";

export default {
  name: "UserAdd",

  metaInfo: {
    title: "Add User",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    "v-select": vSelect,
  },
  data: () => ({
    payload: {
      role: "",
      hotelId: null,
      name: "",
      email: "",
      phoneNumber: "",
    },
    url: null,
    roleOptions: [],
    brandOptions: [],
    isSaving: false,
  }),

  computed: {
    identifications() {
      return this.$store.state.identificationType.identificationTypes || [];
    },
  },

  watch: {
    url(url) {
      if (this.url == null) {
        this.url = "https://via.placeholder.com/350";
      } else {
        this.url = url;
      }
    },
  },

  methods: {
    getBrands() {
      axios
        .get("/hotels")
        .then(({ data: res }) => {
          this.brandOptions = res.data.hotels.map((brand) => {
            return {
              id: brand.id,
              name: brand.name,
            };
          });
        })
        .catch((err) => {
          this.$catchErrorResponse(err);
        });
    },

    getRoles() {
      this.roleOptions = [
        {
          id: 'tenant',
          label: 'Property Admin'
        },
        {
          id: 'admin',
          label: 'Super Admin'
        },
      ]
      // axios
      //   .get("role")
      //   .then(({ data: res }) => {
      //     this.roleOptions = res.data.map((role) => {
      //       role.name = this.$options.filters.capitalize(
      //         role.name.split("_").join(" ")
      //       );
      //
      //       return role;
      //     });
      //   })
      //   .catch((err) => this.$catchErrorResponse(err));
    },

    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.avatar = file;
    },

    removeImage() {
      this.url = "https://via.placeholder.com/350";
      this.payload.avatar = "";
    },

    async storeData() {
      if (!(await this.$validator.validateAll())) {
        return
      }

      this.isSaving = true;
      this.$store
        .dispatch("userManagement/storeData", this.payload)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          })

          this.$router.push("/users");
        })
        .catch((err) => {
          this.$catchErrorResponse(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },

  mounted() {
    if (this.url == null) {
      this.url = "https://via.placeholder.com/350";
    }
  },

  created() {
    if (!moduleUserManagement.isRegistered) {
      this.$store.registerModule("userManagement", moduleUserManagement);
      moduleUserManagement.isRegistered = true;
    }
    if (!identificationTypeStore.isRegistered) {
      this.$store.registerModule("identificationType", identificationTypeStore);
      identificationTypeStore.isRegistered = true;
    }

    this.getBrands();
    this.getRoles();
  },
};
</script>

<style></style>
